<nav class="nav-bar">
  <div class="display-sm">
    <p-skeleton shape="circle" size="5rem" />
    <p-skeleton size="2rem" />
  </div>
  <div class="display-md">
    <p-skeleton shape="circle" size="5rem" />
    <div>
      <p-skeleton width="10rem" height="2rem" />
      <p-skeleton width="10rem" height="2rem" />
      <p-skeleton width="10rem" height="2rem" />
      <p-skeleton width="10rem" height="2rem" />
      <p-skeleton width="10rem" height="2rem" />
      <p-skeleton size="2rem" height="2rem" />
      <p-skeleton width="6rem" height="2rem" />
      <p-skeleton size="2rem" />
    </div>
  </div>
</nav>
