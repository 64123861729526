import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'privacy/:id',
    title: 'GS1PT - Página de Consentimento',
    loadComponent: () => import("./components/consent/consent/consent.component").then(c => c.ConsentComponent)
  },
  {
    path: 'privacy-not-found',
    title: 'GS1PT - Erro de consentimento',
    loadComponent: () => import("./components/consent/consent-error/consent-error.component").then(c => c.ConsentErrorComponent)
  },
  {
    path: '**',
    "title": "GS1PT - Página não encontrada",
    loadComponent: () => import("./components/not-found/not-found.component").then(c => c.NotFoundComponent)
  }
];
