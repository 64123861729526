import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ShellComponent } from "./components/gs1-shell/shell/shell.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  imports: [ShellComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  standalone: true
})
export class AppComponent {

  constructor() { }

}
