import { ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { HeaderFooterService } from '../../../services/header-footer.service';
import { switchMap, takeWhile, tap } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { HeaderSkeletonComponent } from '../header-skeleton/header-skeleton.component';

@Component({
  selector: 'app-shell',
  standalone: true,
  imports: [RouterOutlet, HeaderSkeletonComponent],
  providers: [HeaderFooterService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss'
})
export class ShellComponent implements OnInit, OnDestroy {
  headerContent: WritableSignal<SafeHtml | null> = signal(null);
  footerContent: WritableSignal<SafeHtml | null> = signal(null);
  @Input() loading = signal(true)
  destroy = signal(false)
  constructor(private headerFooterService: HeaderFooterService) { }

  ngOnDestroy(): void {
    this.destroy.set(false)
  }

  ngOnInit(): void {
    this.loadHeaderFooter()
  }
  loadHeaderFooter() {
    this.headerFooterService.getHeaderFooter()
      .pipe(
        switchMap(content => {
          return this.headerFooterService.getStyles()
            .pipe(tap(response => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(response, 'text/html');
              //load html
              this.headerContent.set(content.header)
              this.footerContent.set(content.footer)
              // Append the scripts and styles
              this.appendScriptsAndStyles(doc);
              let btn = document.getElementsByClassName("cmplz-manage-consent")[0] as HTMLElement
              if (btn) {
                btn.style.left = '40px';
                btn.style.right = 'auto';
              }
              this.loading.set(false)
            }))
        }),
        takeWhile(_ => !this.destroy())
      ).subscribe();
  }
  appendScriptsAndStyles(doc: Document): void {

    // Append external stylesheets to head (if any)
    const styleSheets = doc.querySelectorAll('style, link[rel="stylesheet"]');
    styleSheets.forEach(sheet => {
      if (sheet.tagName.toLowerCase() === 'link') {
        const newLink = document.createElement('link') as HTMLLinkElement;;
        newLink.rel = 'stylesheet';
        newLink.href = (sheet as HTMLLinkElement).href;
        document.head.appendChild(newLink);
      } else if (sheet.tagName.toLowerCase() === 'style') {
        const newStyle = document.createElement('style') as HTMLLinkElement;;
        newStyle.textContent = sheet.textContent;
        document.head.appendChild(newStyle);
      }
    });

    // Append scripts to head
    const scripts = doc.querySelectorAll('script');

    scripts.forEach(script => {
      const newScript = document.createElement('script');
      if (script.src) {
        newScript.src = script.src;
        newScript.async = true; // Ensure scripts are executed in order
      } else {
        newScript.textContent = script.textContent;
      }
      document.head.appendChild(newScript);

    });
  };

}
